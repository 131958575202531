/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { useRef } from "react";
import useIntersectionObserver from "@react-hook/intersection-observer";
export default function Example() {
    const containerRef = useRef();
    const lockRef = useRef(false);
    const { isIntersecting } = useIntersectionObserver(containerRef);
    if (isIntersecting) {
        lockRef.current = true;
    }
    return (
        <div ref={containerRef}>
            {lockRef.current && (
                <div className="bg-gray-50 overflow-hidden shadow-lg justify-center flex p-8">
                    <iframe
                        src="https://www.youtube.com/embed/idMIpP9bvas"
                        title="YouTube video player"
                        frameborder="0" Remove Unused JavaScript
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="video"
                    />
                </div>
            )}
        </div>
    );
}